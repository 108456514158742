/*
Todo The types below that build up to the LoanTerms type are all copies of typescript backend types contained in:
 - aven_backend/src/provider/Heracles/pythonService.types.ts
 - aven_backend/src/util/underwritingPolicy.ts
 - aven_backend/src/entity/RateIndex.ts
 It's really messy to need to duplicate here, but at this very moment, this is the
 best option we have.
 */

import { CreditScoreDataPoint, PolicyVariantType, AvenCardProductType } from 'aven_types'
import { ExcessPaymentAllocationMethod } from '@/data/constants'
import { PifScreen } from '@/utils/pifManager'
import { DebtProductAndMetaDataSet } from '@/services/avenAppApi'
import { CustomerInfo } from './customerInfoStore.types'

export enum ProductCategory {
    basic = 'basic',
    prime = 'prime',
    primeV2 = 'primeV2',
    autoPayOptInCashBackPromo = 'autoPayOptInCashBackPromo',
    autoPayDisabledCashBack = 'autoPayDisabledCashBack',
    primeV2AutoPayDisabledCashBack = 'primeV2AutoPayDisabledCashBack',
    primeV3AutoPayDisabledCashBack = 'primeV3AutoPayDisabledCashBack',
    fixedTerm = 'fixedTerm',
    fixedTermAutoPayDisabledCashBack = 'fixedTermAutoPayDisabledCashBack',
}

// Keep in sync with aven_backend/src/provider/Heracles/pythonService.types.ts
export enum OfferContingency {
    titleInsurance = 'titleInsurance', // Currently a column TODO: Move to the general JSON field
    employmentVerification = 'employmentVerification', // Currently a column TODO: Move to the general JSON field
    employmentDurationVerification = 'employmentDurationVerification',
    hazardInsurance = 'hazardInsurance', // Currently a column TODO: Move to the general JSON field
    illinoisAntiPredatory = 'illinoisAntiPredatory',
    mlsListing = 'mlsListing',
    priorLienRelease = 'priorLienRelease',
    lienPositionVerification = 'lienPositionVerification',
    userProvidedFirstLienVerification = 'userProvidedFirstLienVerification',
    floodInsuranceIncompleteChecks = 'floodInsuranceIncompleteChecks',
    appraisalContingency = 'appraisalContingency',
}

export enum RateIndexType {
    WSJ_PRIME = 'WSJ_PRIME', // Wall Street Journal's Prime Rate: https://www.wsj.com/market-data/bonds/moneyrates
    FFTRUL = 'FFTRUL', // Federal Funds Target Range - Upper Limit. (Exact label for what's commonly known as the Federal Funds Rate) https://fred.stlouisfed.org/series/DFEDTARU
}

export interface PartialRateIndex {
    rateValue: number
    rateIndexType: RateIndexType
}

export interface KeyOfferTerms {
    apr: number // On the form 4% -> 4.00 on credit_card_customer, but 0.0400 on loan application. TODO: fix this inconsistency
    margin: number // On the form 4% -> 4.00 on credit_card_customer, but 0.0400 on loan application. TODO: fix this inconsistency
    rateIndex: PartialRateIndex
    lineSize: number
}

export interface OfferTerms extends KeyOfferTerms {
    cashBack: number
    monthlyFee: number // Deprecated, use annualFeeAmount
    annualFeeAmount?: number
    product: ProductCategory
    balanceTransferFeePercent?: number // Defaults to 2.5% if not present
    cashOutFeePercent?: number // Defaults to 2.5% if not present
}

// Keep in sync with aven_backend/src/provider/Heracles/pythonService.types.ts
export enum FullCreditLineContingency {
    passAvenLienPositionHardCut = 'passAvenLienPositionHardCut',
    passPaymentPerformanceHardCut = 'passPaymentPerformanceHardCut',
}

// Keep in sync with aven_backend/src/provider/Heracles/pythonService.types.ts
export interface GenericLoanTerms extends OfferTerms {
    policyName: string
    // policy variants applied to this offer's underwriting
    contingencies: OfferContingency[]
    // introduction available line size; full line size will be available after fullLineSizeContingencies are cleared.
    introLineSize?: number
    fullLineSizeContingencies?: FullCreditLineContingency[]
}

export interface HomeLoanTerms extends GenericLoanTerms {
    // recordingCharges is the amount the states and counties charge us
    recordingCharges?: number
    // effectiveRecordingCharges is the amount we are actually going to charge to the customer
    effectiveRecordingCharges?: number
    effectiveAttorneyRecordingCharges?: number
    policyVariants?: PolicyVariantType[]
    primePromoApr?: number
    primePromoMargin?: number
}

// tslint:disable-next-line:no-empty-interface
export interface CryptoLoanTerms extends GenericLoanTerms {}

export interface AutoLoanTerms extends GenericLoanTerms {
    loanSize: number | null
    loanApr: number | null
    months: number | null
    monthlyPayment: number | null
    monthlySavings: number | null
}

export type LoanTerms = HomeLoanTerms | CryptoLoanTerms | AutoLoanTerms

export enum AutopayCategory {
    STATEMENT_BALANCE = 'STATEMENT_BALANCE',
    MINIMUM_DUE = 'MINIMUM_DUE',
    CUSTOM_AMOUNT = 'CUSTOM_AMOUNT',
}

export interface AutoPayDetails {
    autoPayCategory: AutopayCategory
    aciFundingTokenId: string
    customAmount?: number
    externalBankAccountNickname?: string
}

export interface AutoPaySetting {
    isEnabled: boolean
    autoPayDetails?: AutoPayDetails
}

export interface ScheduledPayment {
    id: number
    paymentAmount: number
    scheduledPaymentDate: Date
    last4AccountNumber: string
}

export const AUTO_PAY_DISABLED: AutoPaySetting = {
    isEnabled: false,
}

export interface ExperimentSpecPayload {
    [experimentType: string]: string[]
}

export interface RescissionInfo {
    isInRescissionPeriod: boolean
    endRescission: Date
}

// Keep in sync with enum AvenCardAccountHolderType in aven_backend/src/entity/aven/avenCardAccountHolderType.ts
export enum AvenCardAccountHolderType {
    UNKNOWN = 'UNKNOWN',
    PRIMARY = 'PRIMARY',
    CO_OWNER = 'CO_OWNER',
    CO_APPLICANT = 'CO_APPLICANT',
}

// Keep in sync with AvenCardActivationStatus in aven_backend/src/entity/avenCreditCard.types.ts
export enum AvenCardActivationStatus {
    unknown = 'unknown',
    pending = 'pending',
    last4DigitValidated = 'last4DigitValidated',
    cashOutFullDrawDrafted = 'cashOutFullDrawDrafted',
    mailerCodeValidated = 'mailerCodeValidated',
    cashOutFullDrawInProgress = 'cashOutFullDrawInProgress',
    cashOutFullDrawError = 'cashOutFullDrawError',
    activated = 'activated',
    internalError = 'internalError',
    _closed = 'closed',
    _canceled = 'canceled',
    _exceededMaxOnlineActivationAttempts = 'exceededMaxOnlineActivationAttempts',
}

// Keep in sync with enum AvenAccountStanding in aven_backend/src/util/avenCardOverviewConstants.ts
export enum AvenAccountStanding {
    unknown = 'unknown',
    current = 'current',
    pastDue = 'pastDue',
    delinquent = 'delinquent',
    overLimit = 'overLimit',
    fraud = 'fraud',
    blocked = 'blocked',
    closed = 'closed',
    pendingClose = 'pendingClose',
    pendingPayoffThenLimitedMode = 'pendingPayoffThenLimitedMode',
    limitedMode = 'limitedMode',
    homeReAttachInProgress = 'homeReAttachInProgress',
    homeReAttachInRescission = 'homeReAttachInRescission',
    expiringFloodInsurance = 'expiringFloodInsurance',
    expiredFloodInsurance = 'expiredFloodInsurance',
    paymentProcessingAfterPastDue = 'paymentProcessingAfterPastDue',
}

export const BT_ALLOWED_ACCOUNT_STANDINGS = [AvenAccountStanding.current, AvenAccountStanding.expiringFloodInsurance]

export enum BalanceTransferBlockReason {
    ACCOUNT_NOT_CURRENT = 'ACCOUNT_NOT_CURRENT',
    NOT_PRIMARY = 'NOT_PRIMARY',
    CARD_NOT_ACTIVATED = 'CARD_NOT_ACTIVATED',
    NO_AVAILABLE_CREDIT = 'NO_AVAILABLE_CREDIT',
    TOS_NOT_ACCEPTED = 'TOS_NOT_ACCEPTED',
    IN_RESCISSION = 'IN_RESCISSION',
}

// Only partial because we don't want to maintain the LoanApplicationStatus enum
// on the frontend. If it comes to that, we *must* create a shared types library.
export interface PartialExistingHomeReAttachPayload {
    jodlLink: string
}

export interface ExpiringFloodInsuranceForAppPayload {
    expiryDate: string
    documentUploadLink: string
}

// reward type is meant to support different variations of the Pay It Forward concept
// a `null` in the return value means that the PIF is a classicPif implementation
export enum PifRewardType {
    classicPif = 'classicPif', // classic PIF
    travelRewardsV0 = 'travelRewardsV0',
    travelTicketsBahamas = 'travelTicketsBahamas',
}

// keep in sync with DEFAULT_PIF_REWARD_TYPE in aven_backend
export const DEFAULT_PIF_REWARD_TYPE: PifRewardType = PifRewardType.classicPif

export interface PifTippingInfo {
    isEligible: boolean
    maxAmount: number
    sourceName?: string
}

export interface MegaphoneMessage {
    megaphoneId: number
    title: string
    description: string
    ctaText: string
    ctaRouteName: string
}

export interface CustomerAccountOverviewMetadata {
    lastSuccessfulSync: Date | null
}

export interface ISimpleInterestLoanConversionDetails {
    planType: string
    amortizationLoanId: number
    amortizationLoanAmountRequested: number
    amortizationLoanMonthlyPaymentInDollars: number
    amortizationLoanRemainingTermInMonths: number
    amortizationLoanTotalPaid: number
    amortizationLoanAprPercentage: number
    simpleInterestLoanId: number
    simpleInterestLoanMonthlyPaymentInDollars: number
    simpleInterestLoanTermInMonths: number
    simpleInterestLoanTotalPaid: number
    totalSavedWithConversion: number
    simpleInterestLoanAprPercentage: number
}

interface InProgressCashOutV2BannerParams {
    isCashOutSubmitted: boolean
    cashOutAmount: number
    formattedSubmissionDate: string
    formattedTransferDate: string
}

export interface PifData {
    tipAmount: number
    linksPerScreen: { [K in PifScreen]: string }
    rewardType: PifRewardType
}

// Keep in sync with ICustomerAccountOverview in aven_backend/src/util/avenCardOverviewConstants.ts
export interface CustomerAccountOverview {
    customerInfo: CustomerInfo
    rescissionInfo: RescissionInfo | undefined
    productType: AvenCardProductType
    cardType: AvenCardAccountHolderType | undefined
    showEnrollAutoPayAndKeepAprDiscount: boolean
    showSimpleInterestConversionInterstitial: boolean
    // Old interestitial data
    simpleInterestConversionSavings: string | undefined
    simpleInterestMonthlyPaymentDecrease: number | undefined
    simpleInterestLoanConversionType: SimpleInterestLoanConversionType | undefined
    // === new interstitial data ===
    simpleInterestLoanConversionSavingDetails:
        | {
              prerenderSimpleInterestConversionTotalDifferenceInNumberOfPayments: number | undefined
              prerenderSimpleInterestConversionTotalSavedByConverting: number | undefined
              prerenderSimpleInterestLoanConversionSavingDetails: ISimpleInterestLoanConversionDetails[] | undefined
          }
        | undefined
    alreadyShownEnrollInAutoPayAndKeepAprDiscount: boolean
    alreadyShownSimpleInterestConversionInterstitialInThisSession: boolean
    alreadyShownDqInterstitialInThisSession: boolean
    alreadyShownInstantBalanceTransferInOnboarding: boolean
    alreadyShownPrimeV3CashOutInActivation: boolean
    minAccountFico: number | undefined
    fastFunding: {
        isEligible: boolean
        hasSubmitted: boolean | undefined
        lineSizeIfApproved: number | undefined
        lineSizePostCardActivation: number | undefined
        decisionETA: Date | undefined
        isApproved: boolean | undefined
    }
    advanceTransactionNotification: {
        isEligibleToCreate: boolean
        mostRecentOngoing: {
            advanceTransactionNotificationFrom: Date
            advanceTransactionNotificationTo: Date
            advanceTransactionNotificationAmountCents: number
        } | null
    }
    isMailerActivationEnabled: boolean | undefined
    isFixedTermUser: boolean | undefined
    allowLongTermInstallmentLoans: boolean | undefined
    mortgagePaymentRewardsEnabled: boolean | undefined
    mortgagePaymentRewardsV2Enabled: boolean | undefined
    maxTotalDailyBtPlusCashOutInDollars: number | undefined
    cardActivationStatus: AvenCardActivationStatus
    cardShippingDate: Date | undefined
    isAccountChargedOff: boolean
    isAccountBTCOEnabled: boolean
    isAccountCOEnabled: boolean
    isAccountBTEnabled: boolean
    isAccountCashOutUpfrontV1Enabled: boolean
    isAccountCashOutUpfrontV2Enabled: boolean
    cashOutUpfrontTransferAmountWithFee: number | undefined
    isAccountInstantBTEnabled: boolean
    shouldShowInstantBtDuringOnboarding: boolean
    isAccountRewardsEnabled: boolean
    apr: number
    isPurchaseInTeaserPromoPeriod: boolean
    purchasePromoAprPercent: number | undefined
    purchasePromoEndDate: Date | undefined
    billingDay: number
    monthlyFee: number
    productCategory: ProductCategory
    desiredProductCategory: ProductCategory
    showPrimeOption: boolean
    loanTermOptions: LoanTerms[]
    isPasscodeSet: boolean
    avenAccountStanding: AvenAccountStanding
    daysDelinquent: number
    isEligibleForPaymentOptions: boolean
    accountNumber: string
    creditLimit: number
    limitedModeCreditLimit: number
    propertyFullAddress?: string
    existingHomeReAttach: PartialExistingHomeReAttachPayload | undefined
    expiringFloodInsurance: ExpiringFloodInsuranceForAppPayload | undefined
    availableCredit: number
    currentBalance: number
    statementRemainingBalance: number
    minimumDue: number
    amountPastDue: number
    dqCuredAt: Date | undefined
    nextStatementDate: string
    paymentDueDate: string | undefined
    // NOTE: This date may be different from paymentDueDate above even though autopay actually runs on paymentDueDate
    // We have this separate display date to make sure we always show a "future" date for autopay next payment date
    // Because between a paymentDueDate and nextStatementDate, we don't know what the next paymentDueDate is
    // https://app.asana.com/0/1200728059455216/1204921081003002/f
    autopayNextPaymentDisplayDate: string | undefined
    dollarToCashbackRewardPointsConversion: number
    shouldUpdateCashBackAfterDisableAutoPay: boolean
    cashbackAfterDisableAutoPay: number
    cashbackAfterEnableAutoPay: number
    cashbackOnRewardsPortalHotelPurchases: number | undefined
    cashbackTermsAndConditionsLink: string | undefined
    showCashOutFeature: boolean
    showBalanceTransferFeature: boolean
    cashOutTotalAmountWithFeeMultiplier: number
    balanceTransferTotalAmountWithFeeMultiplier: number
    cashOutFee: number
    balanceTransferFee: number
    aprIncrementPercentForFixedRateInstallmentLoan: number
    fixedInstallmentApr: number
    isSimpleInterestLoanEnabled: boolean
    totalPendingCashOutPlusBalanceTransfers: number
    madeRecentLargePayment: boolean
    last4CardNumber: string
    signOnBonus: {
        type: string | undefined
        bonusAmount: number | undefined
        sourceName: string | undefined
    }
    pifData: PifData | undefined
    payItForwardTipInfo: PifTippingInfo | undefined
    numberOfCancelledCards: number
    enableAmortizationLoan: boolean
    autoPaySetting: AutoPaySetting
    scheduledPayments: ScheduledPayment[]
    btcDepositAddress: string
    isInMarginCall: boolean
    isFirstSessionForCustomer: boolean
    returnToken: string | undefined
    minMonthlyPaymentFloor: number
    maxCashOutAmountPerTransaction: number
    isMaxCashOutAmountPerTransactionOverDailyLimit: boolean
    allowFullDrawCashOut: boolean
    isCashOutInTeaserPromoPeriod: boolean
    cashOutPromoAprPercent: number | undefined
    cashOutPromoPeriodInMonths: number | undefined
    maxBalanceTransferAmountPerTransaction: number
    isMaxBalanceTransferAmountPerTransactionOverDailyLimit: boolean
    isBalanceTransferInTeaserPromoPeriod: boolean
    balanceTransferPromoAprPercent: number | undefined
    balanceTransferPromoPeriodInMonths: number | undefined
    didAcceptMethodFiTos: boolean
    ambassadorConsentStatus: AmbassadorConsentStatus
    allowSubmitTransactionDisputeInApp: boolean // aka Dispute v1.0
    isCreditLimitReinstatementEligible: boolean
    previousCreditLimit: number
    allowSubmitTransactionDisputeQuestionnaireInApp: boolean // aka Dispute v2.0
    isNowCoreCardEndOfDayProcessingTime: boolean
    experimentOverrides: ExperimentSpecPayload | undefined
    isHausMember: boolean
    isPrimeV3Offer: boolean
    showPrimeV3InActivation: boolean | undefined
    primeV3PromoAprPercent: number | undefined
    hasPrimeV3CoBtFeeDiscount: boolean | undefined
    primeV3PromoCoFee: number | undefined
    primeV3PromoBtFee: number | undefined
    primeV3RemainingLineDrawAmount: number | undefined
    primeV3CutOffDateStr: string | undefined
    isTestingEnvironment: boolean
    isInternalEmployee: boolean
    currentMegaphone: MegaphoneMessage | undefined
    excessPaymentAllocationMethod: ExcessPaymentAllocationMethod | undefined
    canAskForExcessPaymentAllocationMethod: boolean
    hasAuthorizedCardholders: boolean
    limitedSecondaryAccess: boolean
    fullLineSizeContingencies: FullCreditLineContingency[]
    allowedToRequestFullLineSize: boolean
    fullLineSize: number
    creditLimitIncreaseApplicationUrl: string | undefined
    cashOutV2BannerParams: InProgressCashOutV2BannerParams | undefined
    activeMortgagePaymentRewardsStatus: MortgagePaymentRewardsStatus | undefined
    hasActivationRequirements: boolean | undefined
    fixedTermActivationRequirementStatus: AccountActivationRequirementStatus | undefined // deprecated
    activationRequirementStatus: AccountActivationRequirementStatus | undefined
    isBlockedByContingencies: boolean
    blockingContingencyError: string | undefined
    originationFeeRatio: number | undefined // % of the line size used for origination fee (NOT the cash out amount), charged 1x on first draw
    originationFeeAmount: number | undefined // $ amount of the origination fee (e.g. 4.9% OG fee ratio, 10k line = $490), charged 1x on first draw
    firstDrawFeeRatio: number | undefined
    firstDrawFeeAmount: number | undefined
    firstDrawAmountExcludingFee: number | undefined
    firstDrawAmountIncludingFee: number | undefined
    totalAchPaymentAmountFromAciTodayDollarsData: TotalAchPaymentAmountFromAciTodayDollarsData | undefined
    mortgagePaymentRewardsEarned: number | undefined
    mortgagePaymentRewardsV2AvailableSpots: number | undefined
    denyRevolvingCoBt: boolean
    isFullLineMinDrawProduct: boolean
}

// Declaration Merging with credit score related fields
export interface CustomerAccountOverview {
    ficoScore: number | null
    vantageScore: number | null
    dateOfScores: Date | null
    dateOfNextScores: Date | null
    needFull9ssn: boolean | null
    isExperianFrozen: boolean | null
    creditScoreHistory: CreditScoreDataPoint[] | null
}

export type CustomerInfoAndAccountOverview = CustomerAccountOverview & CustomerInfo

export enum CardActivationError {
    CARD_MAX_ONLINE_ACTIVATION_ATTEMPTS_REACHED = 'CARD_MAX_ONLINE_ACTIVATION_ATTEMPTS_REACHED',
    ERROR_CARD_ACTIVATION_DATA_INVALID = 'ERROR_CARD_ACTIVATION_DATA_INVALID',
    ERROR_CARD_ACTIVATION_CLOSED_CARD = 'ERROR_CARD_ACTIVATION_CLOSED_CARD',
    ERROR_CARD_ACTIVATION_OTHER_OWNER = 'ERROR_CARD_ACTIVATION_OTHER_OWNER',
    ERROR_IN_RESCISSION_PERIOD = 'ERROR_IN_RESCISSION_PERIOD',
    ERROR_PRIMARY_CARD_NOT_ACTIVATED = 'ERROR_PRIMARY_CARD_NOT_ACTIVATED',
    ERROR_CARD_ACTIVATION_CSR_ASSISTANCE_NEEDED = 'ERROR_CARD_ACTIVATION_CSR_ASSISTANCE_NEEDED',
    ERROR_BLOCKED_BY_TITLE_INSURANCE = 'ERROR_BLOCKED_BY_TITLE_INSURANCE',
    ERROR_BLOCKED_BY_HAZARD_INSURANCE = 'ERROR_BLOCKED_BY_HAZARD_INSURANCE',
    ERROR_BLOCKED_BY_EMPLOYMENT_VERIFICATION = 'ERROR_BLOCKED_BY_EMPLOYMENT_VERIFICATION',
    ERROR_BLOCKED_BY_MLS_LISTING = 'ERROR_BLOCKED_BY_MLS_LISTING',
    ERROR_BLOCKED_BY_PRIOR_LIEN_RELEASE = 'ERROR_BLOCKED_BY_PRIOR_LIEN_RELEASE',
    ERROR_BLOCKED_BY_PENDING_CONTINGENCIES = 'ERROR_BLOCKED_BY_PENDING_CONTINGENCIES',
    ERROR_BLOCKED_BY_UCC_INCOMPLETE_FLOW = 'ERROR_BLOCKED_BY_UCC_INCOMPLETE_FLOW',
}

// sync with enum HomeReAttachType in aven_backend/src/controller/HomeReAttachController.ts
export enum HomeReAttachType {
    SAME_HOME = 'SAME_HOME',
    DIFFERENT_HOME = 'DIFFERENT_HOME',
    CREDIT_LINE_INCREASE = 'CREDIT_LINE_INCREASE',
}

export interface UpdateAccountOverviewArgs {
    /** If the last successful sync is this Date or more recent, then we will not sync again. */
    freshEnoughDate?: Date
    /** Which data set to return in the call to getDebtProductAndMetaData(). */
    debtProductAndMetaDataSet: DebtProductAndMetaDataSet.all | DebtProductAndMetaDataSet.dynamic
}

export enum SimpleInterestLoanConversionType {
    constantPayment = 'constantPayment',
    constantTerm = 'constantTerm',
}

// sync with enum MortgagePaymentRewardsStatus in aven_backend/src/entity/mortgagePaymentRewardsRequest.ts
export enum MortgagePaymentRewardsStatus {
    INITIATED = 'initiated',
    PAYMENT_SELECTED = 'payment_selected',
    REWARDS_PREREQUISITE_IN_PROGRESS = 'rewards_prerequisite_in_progress',
    REWARDS_PREREQUISITE_FULFILLED = 'rewards_prerequisite_fulfilled',
    REWARDS_PREREQUISITE_EXPIRED = 'rewards_prerequisite_expired',
    NO_VALID_PAYMENT_FOUND = 'no_valid_payment_found',
    NO_RECURRING_STREAM_FOUND = 'no_recurring_stream_found',
    CANCELED = 'canceled',
}

// sync with enum AccountActivationRequirementStatus in aven_backend/src/entity/aven/accountActivationRequirement.ts
export enum AccountActivationRequirementStatus {
    pending = 'pending',
    inProgress = 'inProgress',
    complete = 'complete',
    canceled = 'canceled',
    bypassed = 'bypassed',
}

// sync with enum AmbassadorConsentStatus in aven_backend/src/entity/aven/creditCardCustomer.ts
export enum AmbassadorConsentStatus {
    NOT_ASKED = 'NOT_ASKED',
    DENIED = 'DENIED',
    ACCEPTED = 'ACCEPTED',
}

export enum BecomeAmbassadorPageStep {
    ASK_QUESTION = 'ASK_QUESTION',
    CONFIRMATION = 'CONFIRMATION',
}

// sync with TotalAchPaymentAmountFromAciTodayDollarsData in aven_backend/src/util/avenCardOverviewConstants
export interface TotalAchPaymentAmountFromAciTodayDollarsData {
    totalAchPaymentAmountFromAciTodayDollars: number
    todayEndTime: Date
}

// sync with aven_backend/src/entity/mortgagePaymentRewardsRequest.ts
export enum MortgagePaymentRewardsType {
    cashBackV1 = 'cash_back_v1',
    cashBackV2 = 'cash_back_v2',
}
